var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{},[_c('form',{attrs:{"autocomplete":"off"}},[_c('div',{staticClass:"row"},[_c('CustomFileInput',{attrs:{"className":'col-12',"defaultImg":_vm.fullPathFileFromServer(
            _vm.generalJob.generalJobImagePath,
            _vm.generalJob.defaultImg
          ),"deleteFileStatus":_vm.deleteFileStatus &&
          !_vm.generalJob.generalJobImageIsDefault &&
          _vm.checkPrivilege(_vm.hasGeneralJobDeleteImage())},on:{"changeValue":function($event){_vm.generalJob.mediaFile = $event.file},"deleteFile":function($event){return _vm.$emit('deleteFile')}}})],1),_c('div',{staticClass:"my-card"},[_c('div',{staticClass:"row"},[_c('CustomInput',{attrs:{"className":'col-md-6',"id":(_vm.id + "-fullCode"),"value":_vm.generalJob.fullCode,"title":_vm.$t('GeneralJobs.code'),"imgName":'code.svg'},on:{"changeValue":function($event){_vm.generalJob.fullCode = $event}}}),_c('CustomInput',{attrs:{"className":'col-md-6',"id":(_vm.id + "-generalJobNameAr"),"errors":_vm.errors_generalJobNameAr,"value":_vm.generalJob.generalJobNameAr,"title":_vm.$t('GeneralJobs.nameAr'),"imgName":'generalJobs.svg'},on:{"changeValue":function($event){_vm.generalJob.generalJobNameAr = $event;
            _vm.$v.generalJob.generalJobNameAr.$touch();}}}),_c('CustomInput',{attrs:{"className":'col-md-6',"id":(_vm.id + "-generalJobNameEn"),"errors":_vm.errors_generalJobNameEn,"value":_vm.generalJob.generalJobNameEn,"title":_vm.$t('GeneralJobs.nameEn'),"imgName":'generalJobs.svg'},on:{"changeValue":function($event){_vm.generalJob.generalJobNameEn = $event;
            _vm.$v.generalJob.generalJobNameEn.$touch();}}}),_c('TextArea',{attrs:{"className":'col-md-6',"id":(_vm.id + "-generalJobNotes"),"value":_vm.generalJob.generalJobNotes,"title":_vm.$t('notes'),"imgName":'notes.svg'},on:{"changeValue":function($event){_vm.generalJob.generalJobNotes = $event}}})],1)]),_c('div',{staticClass:"form-actions"},[_c('div',{staticClass:"icon-submit",on:{"click":function($event){$event.preventDefault();return _vm.submitForm.apply(null, arguments)}}},[_c('img',{attrs:{"src":require("@/assets/images/check-icon.svg"),"title":_vm.submitName}})]),_c('div',{directives:[{name:"b-modal",rawName:"v-b-modal",value:(("ConfirmCloseSheet-" + _vm.bottomSheetName)),expression:"`ConfirmCloseSheet-${bottomSheetName}`"}],staticClass:"icon-cancel",on:{"click":function($event){$event.preventDefault();}}},[_c('img',{attrs:{"src":require("@/assets/images/cancel-icon.svg"),"title":_vm.$t('cancel')}})])])])])}
var staticRenderFns = []

export { render, staticRenderFns }